import React, { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import { useNotificationContext } from 'shared/context/Notifications'
import Hidden from 'shared/components/Hidden'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { ChangeQuantityPayload, IncludeProductInOrderPayload, FetchOrderReflinkDetails, Product } from '../../types'
import UnavailableProductsModal from '../UnavailableProductsModal'
import ChangedPricesModal from '../ChangedPricesModal'
import DesktopTable from '../DesktopTable'
import MobileTable from '../MobileTable'
import SmsVerificationModal from '../SmsVerificationModal'
import { fetchCartSummary, changeIncludeInOrder, changeQuantity } from '../../services'

interface Props {
   token: string
}

export default function OrderReflinkContent({ token }: Props) {
   const [openSMSModal, setOpenSMSModal] = useState(false)
   const { addNotification } = useNotificationContext()
   const [data, setData] = useState<FetchOrderReflinkDetails>()
   const [loading, setLoading] = useState(false)
   const { t } = useTranslation(['TRANSLATION', 'ORDER_REFLINK'])
   const [changedPricesModal, setChangedPricesModal] = useState(false)
   const [unavailableModal, setUnavailableModal] = useState(false)

   function handleChangeQuantity(quantity: number, uuid: string) {
      setLoading(true)
      const payload: ChangeQuantityPayload = {
         uuid,
         token,
         quantity,
      }
      changeQuantity(payload)
         .then(() => {
            fetchCartSummary({ token }).then((res) => {
               setData(res)
               if (res.products.some((product: Product) => product.status === 'NOT_AVAILABLE')) {
                  setUnavailableModal(true)
               }
               if (
                  res.products.filter(
                     (product: Product) =>
                        product.cartWholesaleDTO.clientNetPrice !== product.cartWholesaleDTO.oldClientPrice &&
                        product.cartWholesaleDTO.oldClientPrice !== null
                  ).length > 0
               ) {
                  setChangedPricesModal(true)
               }
            })
         })
         .catch((err) => {
            if (isAxiosError<ErrorType>(err)) {
               addNotification(err.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
         .finally(() => setLoading(false))
   }

   function handleChangeIncludeInOrder(cartProductUuid: string, includeProductInOrder: boolean) {
      const payload: IncludeProductInOrderPayload = {
         cartProductUuid,
         token,
         includeProductInOrder,
      }

      changeIncludeInOrder(payload)
         .then(() => {
            fetchCartSummary({ token })
               .then((res) => {
                  setData(res)
                  if (res.products.some((product: Product) => product.status === 'NOT_AVAILABLE')) {
                     setUnavailableModal(true)
                  }
                  if (
                     res.products.filter(
                        (product: Product) =>
                           product.cartWholesaleDTO.clientNetPrice !== product.cartWholesaleDTO.oldClientPrice &&
                           product.cartWholesaleDTO.oldClientPrice !== null
                     ).length > 0
                  ) {
                     setChangedPricesModal(true)
                  }
               })
               .finally(() => setLoading(false))
         })
         .catch((err) => {
            if (isAxiosError<ErrorType>(err)) {
               addNotification(err.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
         .finally(() => setLoading(false))
   }

   useEffect(() => {
      setLoading(true)
      fetchCartSummary({ token })
         .then((res) => {
            setData(res)
            if (res.products.some((product) => product.status === 'NOT_AVAILABLE')) {
               setUnavailableModal(true)
            }
            if (
               res.products.filter(
                  (product) =>
                     product.cartWholesaleDTO.clientNetPrice !== product.cartWholesaleDTO.oldClientPrice &&
                     product.cartWholesaleDTO.oldClientPrice !== null
               ).length > 0
            ) {
               setChangedPricesModal(true)
            }
         })
         .catch((err) => {
            if (isAxiosError<ErrorType>(err)) {
               addNotification(err.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
         .finally(() => setLoading(false))
   }, [token])

   return (
      <Box sx={{ width: '100%', paddingX: 2 }}>
         <Typography variant="body1" color="grayText">
            {t('ORDER_REFLINK:table.availableProducts')}
         </Typography>
         {data ? (
            <>
               {data.products.length > 0 && (
                  <Hidden width="mdDown">
                     <DesktopTable
                        formattedGrossValue={data.formattedGrossValue}
                        formattedNetValue={data.formattedNetValue}
                        handleChangeQuantity={handleChangeQuantity}
                        handleIncludeInOrder={handleChangeIncludeInOrder}
                        disableButtons={loading}
                        products={data.products.filter((item) => item.status !== 'NOT_AVAILABLE')}
                        services={data.additionalServices}
                        formattedAdditionalServicesGrossValue={data.formattedAdditionalServicesGrossValue}
                        formattedAdditionalServicesNetValue={data.formattedAdditionalServicesNetValue}
                        formattedClientNetValueWithAdditionalServices={
                           data.formattedClientNetValueWithAdditionalServices
                        }
                        formattedClientGrossValueWithAdditionalServices={
                           data.formattedClientGrossValueWithAdditionalServices
                        }
                     />
                  </Hidden>
               )}
               {data.products.length > 0 && (
                  <Hidden width="mdUp">
                     <MobileTable
                        formattedGrossValue={data.formattedGrossValue}
                        formattedNetValue={data.formattedNetValue}
                        handleChangeQuantity={handleChangeQuantity}
                        handleIncludeInOrder={handleChangeIncludeInOrder}
                        disableButtons={loading}
                        products={data.products.filter((item) => item.status !== 'NOT_AVAILABLE')}
                        services={data.additionalServices}
                        formattedAdditionalServicesGrossValue={data.formattedAdditionalServicesGrossValue}
                        formattedAdditionalServicesNetValue={data.formattedAdditionalServicesNetValue}
                        formattedClientNetValueWithAdditionalServices={
                           data.formattedClientNetValueWithAdditionalServices
                        }
                        formattedClientGrossValueWithAdditionalServices={
                           data.formattedClientGrossValueWithAdditionalServices
                        }
                     />
                  </Hidden>
               )}
               {data.products.length === 0 && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', my: 10 }}>
                     <Typography variant="h4">{t('ORDER_REFLINK:table.noProducts')}</Typography>
                  </Box>
               )}
            </>
         ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 3, width: '100%' }}>
               <CircularProgress color="primary" />
            </Box>
         )}
         <Box
            sx={{
               display: 'flex',
               gap: { xs: 3 },
               flexDirection: { md: 'row', xs: 'column' },
               justifyContent: { md: 'space-between' },
            }}
         >
            <TextField
               label={t('ORDER_REFLINK:cartDescription')}
               multiline
               defaultValue={data?.cartDescription}
               InputLabelProps={{ shrink: true }}
               inputProps={{ readOnly: true }}
               rows={6}
               fullWidth
               sx={{ maxWidth: { md: 400 } }}
            />
            <ContainedButton
               onClick={() => {
                  setOpenSMSModal(true)
               }}
               disabled={loading || data?.products.every((product) => !product.includedProductInOrder)}
               variant="contained"
               sx={{ alignSelf: { md: 'flex-start' } }}
            >
               {t('ORDER_REFLINK:orderButton')}
            </ContainedButton>
         </Box>
         <UnavailableProductsModal
            data={data?.products.filter((product) => product.status === 'NOT_AVAILABLE')}
            open={unavailableModal}
            handleClose={() => setUnavailableModal(false)}
         />
         {changedPricesModal && (
            <ChangedPricesModal
               data={data?.products.filter(
                  (product) =>
                     product.cartWholesaleDTO.oldClientPrice &&
                     product.cartWholesaleDTO.oldClientPrice !== product.cartWholesaleDTO.clientNetPrice &&
                     product.status !== 'NOT_AVAILABLE'
               )}
               open={changedPricesModal}
               handleClose={() => setChangedPricesModal(false)}
            />
         )}
         <SmsVerificationModal token={token} open={openSMSModal} handleClose={() => setOpenSMSModal(false)} />
      </Box>
   )
}
