import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { changeMessageStatus, fetchMessages } from './services'
import { StateType, ChangeMessageStatusPayload } from './types'

async function getMessages() {
   const request = await fetchMessages().then((response) =>
      response.messages.map((message) => ({
         ...message,
      }))
   )

   return request
}

export function useMessagesQuery(): UseQueryResult<StateType> {
   return useQuery('messages', getMessages, { staleTime: 10 * (60 * 1000) })
}

export function useChangeMessageStatusMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, ChangeMessageStatusPayload, unknown>(
      (payload) => changeMessageStatus(payload),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('messages')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}
