import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import NotificationsIcon from '@mui/icons-material/Notifications'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import LockIcon from '@mui/icons-material/Lock'
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import { useQueryClient } from 'react-query'
import Tooltip from '@mui/material/Tooltip'
import Divider from '@mui/material/Divider'
import Popover from '@mui/material/Popover'
import Select from '@mui/material/Select'
import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'

import { AuthorizedVersion as LanguagePopover } from 'shared/components/LanguagePopover'
import swapUserLocationService from 'shared/services/swapUserLocation'
import { useNotificationsQuery } from 'shared/store/useNotifications'
import { useNotificationContext } from 'shared/context/Notifications'
import { getRefreshToken } from 'shared/utils/helpers/refreshToken'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import { useLocationsQuery } from 'shared/store/useLocations'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import SearchProduct from 'shared/components/SearchProduct'
import { useMessagesQuery } from 'shared/store/useMessages'
import { useProfileQuery } from 'shared/store/useProfile'
import smallLogo from 'assets/images/logo-small.png'
import BadgeIcon from 'shared/components/BadgeIcon'
import logo from 'assets/images/logo-large.png'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { AppBar, StyledIconButton, StyledLink, StyledLogo, StyledSmallLogo } from './styles'
import ChangePasswordModal from '../ChangePasswordModal'

export default function Header() {
   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
   const { t } = useTranslation('AUTHORIZED')
   const [openChangePasswordModal, setOpenChangePasswordModal] = useState<boolean>(false)
   const { data: useProfileData } = useProfileQuery()
   const theme = useTheme()
   const queryClient = useQueryClient()
   const { user, logout } = useLogin()
   const { addNotification } = useNotificationContext()
   const { data } = useNotificationsQuery()
   const { data: messages } = useMessagesQuery()
   const { data: locationsData } = useLocationsQuery()
   const location = useLocation()

   const MENU_OPTIONS = [
      {
         label: t('header.menuItems.homePage'),
         linkTo: '/dashboard',
         icon: <HomeOutlinedIcon color="primary" />,
      },
   ]

   function handleLocationSwap(locationUuid: string) {
      swapUserLocationService(locationUuid)
         .then((res) => {
            if (res.status === 200) {
               getRefreshToken()
               addNotification(t('header.successLocationSwap'), 'success')
               queryClient.invalidateQueries('userProfile')
               queryClient.invalidateQueries('userStats')
               invalidateCarts(queryClient)
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(
                  error.response?.data?.errors[0].code || t('AUTHORIZED:header.failedLocationSwap'),
                  'error'
               )
            } else {
               addNotification(t('AUTHORIZED:header.failedLocationSwap'), 'error')
            }
         })
   }

   return (
      <AppBar>
         <Stack direction="row" alignItems="center" width="100%" px={2}>
            <RouterLink
               style={{
                  marginRight: 1,
                  textDecoration: 'none',
               }}
               to="/dashboard"
            >
               <StyledLogo src={logo} alt="logo" />
            </RouterLink>
            <RouterLink
               style={{
                  marginRight: 1,
                  textDecoration: 'none',
               }}
               to="/dashboard"
            >
               <StyledSmallLogo src={smallLogo} alt="logo" />
            </RouterLink>
            <Box sx={{ flexGrow: 1 }} />
            <SearchProduct />
            <Box sx={{ flexGrow: 1 }} />
            {useProfileData && !user.roles.includes(UserRoles.DEMO) && locationsData && (
               <Select
                  disabled={locationsData?.locations.filter((item) => !item.locationSettings.disabled).length === 1}
                  onChange={(e) => handleLocationSwap(e.target.value)}
                  variant="standard"
                  value={useProfileData?.locationUuid}
               >
                  {locationsData?.locations.map((item) => (
                     <MenuItem key={item.locationSettings.uuid} value={item.locationSettings.uuid}>
                        {item.locationSettings.name}
                     </MenuItem>
                  ))}
               </Select>
            )}
            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
               {(user.roles.includes(UserRoles.SUBSCRIPTION) || user.roles.includes(UserRoles.DEMO)) && (
                  <IconButton>
                     <RouterLink
                        style={{
                           fontWeight: 'bold',
                           color: location.pathname.includes('main-cart') ? theme.palette.primary.dark : 'gray',
                        }}
                        to="main-cart"
                     >
                        <BadgeIcon badgeType="CART_HEADER" />
                     </RouterLink>
                  </IconButton>
               )}
               <StyledIconButton unreadexist={data ? data.filter((item) => !item.read).length > 0 : false}>
                  <RouterLink
                     style={{
                        color: location.pathname.includes('notifications') ? theme.palette.primary.dark : 'gray',
                     }}
                     to="notifications"
                  >
                     <Badge
                        badgeContent={data ? data.filter((item) => !item.read).length : 0}
                        color="error"
                        sx={{ '& .MuiBadge-badge': { color: 'white', fontWeight: 'normal' } }}
                        max={99}
                     >
                        <NotificationsIcon sx={{ fontSize: 27 }} />
                     </Badge>
                  </RouterLink>
               </StyledIconButton>
               <StyledIconButton unreadexist={messages ? messages.filter((item) => !item.read).length > 0 : false}>
                  <RouterLink
                     style={{
                        color: location.pathname.includes('messages') ? theme.palette.primary.dark : 'gray',
                     }}
                     to="messages"
                  >
                     <Badge
                        badgeContent={messages ? messages.filter((item) => !item.read).length : 0}
                        color="error"
                        sx={{ '& .MuiBadge-badge': { color: 'white', fontWeight: 'normal' } }}
                        max={99}
                     >
                        <EmailOutlinedIcon sx={{ fontSize: 27 }} />
                     </Badge>
                  </RouterLink>
               </StyledIconButton>
               <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                  <AccountCircleOutlinedIcon sx={{ fontSize: 27 }} color={anchorEl ? 'primary' : 'inherit'} />
               </IconButton>

               <Popover
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  onClose={() => setAnchorEl(null)}
                  sx={{ mt: 4 }}
                  anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                  }}
               >
                  <Box sx={{ my: 2, px: 2.5 }}>
                     <Stack direction="row" spacing={1}>
                        <Typography variant="body1" noWrap>
                           {useProfileData?.userName}
                        </Typography>
                        <Tooltip title={t('header.menuItems.pointsTooltip') || ''}>
                           <StyledLink href="https://nagrody.motorro.pl" target="_blank">
                              {useProfileData && useProfileData?.loyaltyPoints <= 0
                                 ? 0
                                 : useProfileData?.loyaltyPoints.toFixed(0)}
                           </StyledLink>
                        </Tooltip>
                     </Stack>
                  </Box>
                  <Divider sx={{ my: 1 }} />
                  {MENU_OPTIONS.map((option) => (
                     <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={() => setAnchorEl(null)}
                        sx={{ typography: 'body2' }}
                     >
                        {option.icon}
                        <Box
                           sx={{
                              width: 13,
                              height: 20,
                           }}
                        />

                        {option.label}
                     </MenuItem>
                  ))}
                  <MenuItem
                     onClick={() => {
                        setOpenChangePasswordModal(true)
                        setAnchorEl(null)
                     }}
                     sx={{ typography: 'body2' }}
                  >
                     <LockIcon color="primary" />
                     <Box
                        sx={{
                           width: 13,
                           height: 20,
                        }}
                     />

                     {t('TRANSLATION:changePassword')}
                  </MenuItem>
                  <Divider />
                  <MenuItem sx={{ typography: 'body2' }} onClick={() => logout('/')}>
                     <LogoutIcon color="primary" />
                     <Box
                        sx={{
                           width: 13,
                           height: 20,
                        }}
                     />
                     {t('header.menuItems.logout')}
                  </MenuItem>
               </Popover>
               <LanguagePopover />
            </Stack>
         </Stack>
         {user.roles.includes(UserRoles.DEMO) && (
            <Box
               sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: 'white',
                  width: '100%',
                  maxHeight: '40px',
                  mt: 8,
                  position: 'fixed',
                  backgroundColor: 'red',
               }}
            >
               {t('TRANSLATION:demoAccount')}
            </Box>
         )}
         <ChangePasswordModal open={openChangePasswordModal} handleClose={() => setOpenChangePasswordModal(false)} />
      </AppBar>
   )
}
