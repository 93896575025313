import React, { useEffect, useRef, useState } from 'react'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { useProfileQuery } from 'shared/store/useProfile'
import { useNotificationContext } from 'shared/context/Notifications'

import { RootStyle, DropZoneStyle, PlaceholderStyle, StyledInput, StyledImage } from './styles'
import { sendLogoService, removeLogoService } from '../../services/sendLogoService'

export default function UploadLogoAdmin() {
   const { t } = useTranslation('COMPANY_SETTINGS')
   const { data, refetch } = useProfileQuery()
   const [fileError, setFileError] = useState('')
   const { addNotification } = useNotificationContext()
   const [logo, setLogo] = useState<Blob>()
   const [logoPreview, setLogoPreview] = useState('')
   const logoRef = useRef<HTMLInputElement>(null)

   function handleLogoChange(e: React.ChangeEvent<HTMLInputElement>) {
      const file = e.target.files?.[0]
      if (file) {
         if (['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
            if (file.size < 1024 * 512) {
               setLogoPreview(URL.createObjectURL(file))
               setFileError('')
               setLogo(file)
            } else {
               setFileError('sizeError')
            }
         } else {
            setFileError('typeError')
         }
      }
   }

   function handleSent() {
      const formData = new FormData()
      if (logo) {
         formData.append('file', logo)
      }

      sendLogoService(formData)
         .then((res) => {
            if (res.status === 200) {
               addNotification('successFileTransfer', 'success')
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedFileTransfer', 'error')
            } else {
               addNotification('failedFileTransfer', 'error')
            }
         })
         .finally(() => refetch())
   }

   function handleDelete() {
      removeLogoService()
         .then((res) => {
            if (res.status === 204) {
               addNotification('successLogoRemove', 'success')
               refetch()
               setLogo(undefined)
               setLogoPreview('')
               if (logoRef.current) {
                  logoRef.current.value = ''
               }
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedLogoRemove', 'error')
            } else {
               addNotification('failedLogoRemove', 'error')
            }
         })
   }

   useEffect(() => {
      if (logo) {
         handleSent()
      }
   }, [logo])

   return (
      <div>
         <RootStyle>
            <Tooltip placement="top" title={t('COMPANY_SETTINGS:clickToAddLogoLabel') || ''}>
               <DropZoneStyle>
                  <PlaceholderStyle>
                     <AddAPhotoIcon sx={{ width: 32, height: 32, position: 'absolute', bottom: 30, left: 70 }} />
                     <StyledInput ref={logoRef} onChange={handleLogoChange} type="file" />
                  </PlaceholderStyle>
                  {data?.image && <StyledImage src={logoPreview || `data:image/png;base64,${data?.image}`} alt="" />}
               </DropZoneStyle>
            </Tooltip>
         </RootStyle>
         <Typography variant="body2" sx={{ color: 'text.secondary', mt: 0.8 }}>
            {t('COMPANY_SETTINGS:allowedLabel')}
            <br />
            {t('COMPANY_SETTINGS:maxSizeLabel')}
         </Typography>
         {fileError === 'sizeError' && (
            <Alert onClose={() => setFileError('')} severity="error">
               {t('COMPANY_SETTINGS:sizeError')}
            </Alert>
         )}
         {fileError === 'typeError' && (
            <Alert onClose={() => setFileError('')} severity="error">
               {t('COMPANY_SETTINGS:typeError')}
            </Alert>
         )}
         <Button
            disabled={!logo && !data?.image}
            sx={{ mt: 1 }}
            onClick={handleDelete}
            variant="outlined"
            color="error"
         >
            {t('COMPANY_SETTINGS:removeLogo')}
         </Button>
      </div>
   )
}
