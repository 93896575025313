import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

type Result = {
   status: number
   data: string
}

export default function generateReflinkService(cartUuid: string) {
   let errMessage = ''

   return httpClient
      .post<Result>(`/order/reflink/generate/${cartUuid}`)
      .then((res) => ({
         status: res.status,
         reflink: res.data as unknown as string,
      }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('ORDERS HISTORY changeOrderName service', errMessage, 'error')
         throw error
      })
}
