import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'

import { fetchNotCooperatingWholesalersData } from 'shared/services/fetchNotCooperationWholesalers'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import RadiusTableWrapper from 'shared/components/RadiusTableWrapper'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { establishCooperationService } from '../../services'
import { CenteredTableCell } from '../../styles'
import SalerLogo from '../Elements/SalerLogo'

interface Props {
   productID: Nullable<string>
   locationID?: string
}

export default function NoCooperationTable({ productID, locationID }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'PRODUCT_DETAILS'])
   const { addNotification } = useNotificationContext()
   const { user: userData } = useLogin()
   const [data, setData] = useState<NoCooperatingWholesaler[]>([])

   async function onEstablishCoop(wholesaleUUID: string) {
      if (userData.roles.includes(UserRoles.DEMO)) {
         addNotification('PRODUCT_DETAILS:tables.featureNotForDemo', 'error')
      } else if (productID && locationID) {
         await establishCooperationService(locationID, {
            productUuid: productID,
            wholesaleUuid: wholesaleUUID,
         })
            .then(({ status }) => {
               if (status === 204) {
                  addNotification('establishCoopSuccess', 'success')
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
      }
   }

   async function init(product: string) {
      await fetchNotCooperatingWholesalersData(product).then((res) => {
         setData(res.notCooperatingWholesalers)
      })
   }

   useEffect(() => {
      if (productID) {
         init(productID)
      }
   }, [productID])

   return data.length ? (
      <Box>
         <Typography variant="body1" sx={{ ml: 1, opacity: 0.5 }}>
            {t('PRODUCT_DETAILS:tables.noCooperationHeading')}
         </Typography>
         <RadiusTableWrapper>
            <Table
               sx={{
                  border: '1px solid rgba(224, 224, 224, 1)',
                  width: 700,
               }}
            >
               <TableHead sx={{ padding: 3, backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                  <TableRow>
                     <CenteredTableCell>
                        <Typography sx={{ opacity: 0.5 }}>{t('TRANSLATION:columnNames.wholesaler')}</Typography>
                     </CenteredTableCell>
                     <CenteredTableCell>
                        <Typography sx={{ opacity: 0.5 }}>{t('TRANSLATION:columnNames.totalAvailability')}</Typography>
                     </CenteredTableCell>
                     <TableCell />
                  </TableRow>
               </TableHead>
               <TableBody>
                  {data.map((item) => (
                     <TableRow key={item.wholesaleUuid}>
                        <CenteredTableCell>
                           <SalerLogo value={item.wholesaleName} />
                        </CenteredTableCell>
                        <CenteredTableCell>
                           {item.totalAvailability &&
                           item.totalAvailability <= item.wholesaleInfiniteAvailabilityThreshold
                              ? item.totalAvailability
                              : `> ${item.wholesaleInfiniteAvailabilityThreshold}`}
                        </CenteredTableCell>
                        <TableCell>
                           {item.cooperationStatus === 'PENDING' ? (
                              <Typography color="primary" sx={{ fontSize: 16 }}>
                                 {t('PRODUCT_DETAILS:tables.establishingCoop')}
                              </Typography>
                           ) : (
                              <Tooltip
                                 title={t('PRODUCT_DETAILS:tables.cantEstablishCoop') || ''}
                                 disableHoverListener={item.cooperationType === 'COOPERATION_EMAIL'}
                              >
                                 <div>
                                    <ContainedButton
                                       onClick={() => onEstablishCoop(item.wholesaleUuid)}
                                       variant="contained"
                                       disabled={
                                          !data ||
                                          item.cooperationType !== 'COOPERATION_EMAIL' ||
                                          (!userData.roles.includes(UserRoles.LOCALIZATION_ADMIN) &&
                                             !userData.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN))
                                       }
                                    >
                                       {t('PRODUCT_DETAILS:tables.addCooperationButton')}
                                    </ContainedButton>
                                 </div>
                              </Tooltip>
                           )}
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </RadiusTableWrapper>
      </Box>
   ) : null
}
