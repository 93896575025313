import React, { useState } from 'react'
import Papa from 'papaparse'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/Cancel'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { useProfileQuery } from 'shared/store/useProfile'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { ProcessFileServiceType } from './types'
import processFileService from './processFileService'
import { de, en, pl } from './locales'
import { StyledLink } from '../ErpProductCard/styles'

export default function CartFromCsv() {
   const theme = useTheme()
   const { user } = useLogin()
   const [csvValues, setCsvValues] = useState<string[][]>()
   const [filename, setFilename] = useState<string>()
   const { data } = useProfileQuery()
   const [conditionError, setConditionError] = useState<boolean>(false)
   const navigate = useNavigate()
   const { i18n, t } = useTranslation('CART_CSV')
   const { addNotification } = useNotificationContext()

   i18n.addResourceBundle('pl-PL', 'CART_CSV', pl)
   i18n.addResourceBundle('en-EN', 'CART_CSV', en)
   i18n.addResourceBundle('de-DE', 'CART_CSV', de)

   const isAdmin =
      user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) || user.roles.includes(UserRoles.LOCALIZATION_ADMIN)

   function parseCSV(event: React.ChangeEvent<HTMLInputElement>) {
      if (event.target.files) {
         setFilename(
            event.target.files[0].name.length > 13
               ? `${event.target.files[0].name.slice(0, 13)}...`
               : event.target.files[0].name
         )
         Papa.parse(event.target.files[0], {
            header: false,
            complete(result: any) {
               setCsvValues(result.data)
            },
         })
      }
   }

   function handleForward() {
      if (isAdmin) {
         navigate('/dashboard/settings', { state: 'csvCart' })
      }
   }

   function sendToProcess() {
      if (csvValues) {
         const productsArray: PropType<ProcessFileServiceType, 'products'> = []
         csvValues.map((value) => {
            if (value.length > 1 && value) {
               if (value.length === 3 && value[2]) {
                  if (data?.priceType === 'GROSS') {
                     productsArray.push({
                        productIndex: value[0],
                        quantity: !Number.isNaN(Number(value[1])) ? Number(value[1]) : 1,
                        grossPriceThreshold: Number(value[2]),
                     })
                  } else {
                     productsArray.push({
                        productIndex: value[0],
                        quantity: !Number.isNaN(Number(value[1])) ? Number(value[1]) : 1,
                        netPriceThreshold: Number(value[2]),
                     })
                  }
               } else {
                  productsArray.push({
                     productIndex: value[0],
                     quantity: !Number.isNaN(Number(value[1])) ? Number(value[1]) : 1,
                  })
               }
            } else if (value[0]) {
               if (value[0].split(';').length === 3 && value[0].split(';')[2]) {
                  if (data?.priceType === 'GROSS') {
                     productsArray.push({
                        productIndex: value[0].split(';')[0],
                        quantity: !Number.isNaN(value[0].split(';')[1]) ? Number(value[0].split(';')[1]) : 1,
                        grossPriceThreshold: Number(value[0].split(';')[2]),
                     })
                  } else {
                     productsArray.push({
                        productIndex: value[0].split(';')[0],
                        quantity: !Number.isNaN(value[0].split(';')[1]) ? Number(value[0].split(';')[1]) : 1,
                        netPriceThreshold: Number(value[0].split(';')[2]),
                     })
                  }
               } else {
                  productsArray.push({
                     productIndex: value[0].split(';')[0],
                     quantity: !Number.isNaN(value[0].split(';')[1]) ? Number(value[0].split(';')[1]) : 1,
                  })
               }
            }
            return null
         })
         if (productsArray.length === 0) {
            addNotification(t('emptyProducts'), 'error')
         } else {
            const payload: ProcessFileServiceType = {
               cartName: filename?.split('.')[0] || '',
               products: productsArray,
            }
            processFileService(payload)
               .then((res) => {
                  if (res.status === 204) {
                     addNotification(t('successAlert'), 'success')
                     setCsvValues(undefined)
                  }
               })
               .catch((error) => {
                  if (isAxiosError<ErrorType>(error)) {
                     if (error.response?.data.errors[0].code === 'errors.field.mandatory') {
                        addNotification('CART_CSV:noIndexOrQuantityError', 'error')
                     } else {
                        addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
                     }
                  } else {
                     addNotification('failedSave', 'error')
                  }
               })
         }
      }
   }

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, my: 5 }}>
         <Typography variant="h3" color="primary">
            {t('title')}
         </Typography>
         {!csvValues ? (
            <Box
               sx={{
                  border: `2px solid ${theme.palette.primary.light}`,
                  borderRadius: 0.5,
                  height: 40,
                  maxWidth: 130,
                  color: theme.palette.primary.light,
                  overflow: 'hidden',
                  '&:hover': {
                     borderColor: theme.palette.primary.darker,
                     color: theme.palette.primary.darker,
                     '*': {
                        cursor: 'pointer',
                     },
                  },
               }}
            >
               <Box sx={{ display: 'flex', justifyItems: 'center' }}>
                  <span style={{ zIndex: 1, fontSize: 22, marginTop: 2, margin: '0 auto' }}>{t('importButton')}</span>
               </Box>
               <input
                  style={{
                     zIndex: 2,
                     position: 'relative',
                     height: 43,
                     top: -53,
                     fontSize: 0,
                     opacity: 0,
                     width: 120,
                  }}
                  type="file"
                  accept=".csv"
                  onChange={(e) => parseCSV(e)}
               />
            </Box>
         ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, fontSize: 20 }}>
               {filename}
               <IconButton sx={{ '&:hover': { color: 'red' } }} onClick={() => setCsvValues(undefined)}>
                  <CancelIcon />
               </IconButton>
            </Box>
         )}
         {csvValues ? (
            <Box>
               <ContainedButton sx={{ fontSize: 14 }} onClick={sendToProcess} variant="contained">
                  {t('createCartButton')}
               </ContainedButton>
            </Box>
         ) : (
            <Box sx={{ textAlign: 'center' }}>
               <Typography variant="h6">
                  {t('instructions.main')}
                  <br />
                  {t('instructions.one')}
                  <br />
                  {t('instructions.two')}
                  <br />
                  {t('instructions.three')}
                  <br />
                  {t('instructions.four')}
                  <br />
                  {t('instructions.five')}
                  <br />
                  {t('instructions.six')}
                  <br />
                  {t('instructions.seven')}
               </Typography>
               <Typography sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }} variant="h6">
                  {t('importExample')}
                  <StyledLink
                     sx={{ fontSize: 15 }}
                     href={`${process.env.REACT_APP_FILES_URL}/assets/cart_example.csv`}
                     download="cart_example.csv"
                  >
                     {t('download')}
                  </StyledLink>
               </Typography>
            </Box>
         )}
         <Snackbar
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            open={conditionError}
            autoHideDuration={10000}
            onClose={() => setConditionError(false)}
         >
            <Alert
               sx={{
                  fontWeight: 'bold',
                  pt: 0,
                  '&:hover': {
                     cursor: 'pointer',
                     userSelect: 'none',
                  },
               }}
               severity="error"
               onClick={handleForward}
            >
               {isAdmin ? t('noConditionsAlert') : t('noConditionsAlertWorker')}
            </Alert>
         </Snackbar>
      </Box>
   )
}
