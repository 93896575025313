import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormik, Form, FormikProvider } from 'formik'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'

import validatePhoneNumber from 'shared/utils/helpers/validatePhoneNumber'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import InputField from 'shared/components/InputField'
import MuiPhone from 'shared/components/PhoneInput'
import { httpClient } from 'shared/lib'

import { SmsVerificationInitialValues, SubmitOrderResult, VerifySMSCodeResult } from '../../types'
import { smsVerificationSchema } from './schema'
import { TypographyLink } from '../../styles'

interface Props {
   open: boolean
   handleClose: () => void
   token: string
}

export default function SmsVerificationModal({ open, token, handleClose }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'ORDER_REFLINK'])
   const { addNotification } = useNotificationContext()
   const [smsCodeError, setSmsCodeError] = useState('')
   const [phoneNumberEror, setPhoneNumberError] = useState(false)
   const [isLoading, setIsLoadding] = useState(false)
   const [step, setStep] = useState(0)
   const navigate = useNavigate()

   const formik = useFormik<SmsVerificationInitialValues>({
      initialValues: {
         phoneNumber: '',
         smsCode: '',
      },
      validationSchema: smsVerificationSchema,
      onSubmit: handleSubmit,
   })

   const { values } = formik

   async function getSmsCode() {
      const { phoneNumber } = formik.values

      try {
         await httpClient.post('sms/send', { phoneNumber })
         setSmsCodeError('')
         setStep(1)
      } catch (error) {
         if (isAxiosError<ErrorType>(error)) {
            if (error.response?.data.errors[0].code === 'errors.sms.sending-issue') {
               setSmsCodeError('phoneNumberNotFound')
            } else {
               setSmsCodeError('connectionError')
            }
         }
      }
   }

   async function validateSmsCode() {
      await httpClient
         .post<VerifySMSCodeResult>('sms/verify', { smsCode: values.smsCode })
         .then((res) => {
            if (res.status === 200) {
               return res.data.valid
            }
            return false
         })
         .catch((err) => {
            if (isAxiosError<ErrorType>(err)) {
               addNotification(err.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
            return false
         })
      return false
   }

   async function sendOrder() {
      setIsLoadding(true)
      await httpClient
         .post<SubmitOrderResult>('order/reflink/createOrder', {
            token,
            phoneNumber: values.phoneNumber,
         })
         .then((res) => {
            if (res.status === 200 && res.data) {
               httpClient
                  .post('notifications/addOrderReflinkNotification', {
                     type: 'CLIENT_FINISHED_ORDER',
                     message: 'Wycena, którą wysłałeś została właśnie zrealizowana.',
                     phoneNumber: values.phoneNumber,
                     token,
                     orderUuid: res.data.orderUuids[0],
                  })
                  .then(() => {
                     navigate('/order-reflink-complete')
                  })
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || t('ORDER_REFLINK:orderSubmitError'), 'error')
            } else {
               addNotification(t('ORDER_REFLINK:orderSubmitError'), 'error')
            }
         })
         .finally(() => setIsLoadding(false))
   }

   function handleSubmit() {
      formik.validateForm().then(async (errors) => {
         const isValidForm = Object.keys(errors).length === 0
         if (isValidForm) {
            const smsCodeIsValid = await validateSmsCode()
            if (smsCodeIsValid) {
               sendOrder()
            } else {
               formik.setFieldError('smsCode', t('ORDER_REFLINK:smsVerification.wrongSmsCode'))
            }
         }
      })
   }

   function handlePhoneNumber(value: string, country: string) {
      formik.setFieldValue('phoneNumber', value)
      const result = validatePhoneNumber(value, country)
      if (result === false) {
         setPhoneNumberError(true)
      } else {
         setPhoneNumberError(false)
      }
   }

   function handleStepChange(nextStep: number) {
      if (nextStep === 0) setStep(0)
      else if (nextStep === 1) {
         getSmsCode()
      }
   }

   function renderContent() {
      switch (step) {
         case 0:
            return (
               <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <DialogTitle sx={{ textAlign: 'center' }} color="primary">
                     <Typography sx={{ fontSize: 20 }}>
                        {t('ORDER_REFLINK:smsVerification.phoneNumberTitle')}
                     </Typography>
                  </DialogTitle>
                  <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                     <Typography variant="body2" sx={{ mb: 1 }} color="grayText">
                        {t('ORDER_REFLINK:smsVerification.phoneNumberText')}
                     </Typography>
                     <MuiPhone
                        variant="standard"
                        value={formik.values.phoneNumber}
                        onChange={(value, country) => handlePhoneNumber(value, country)}
                     />
                     {smsCodeError && (
                        <Alert severity="error" sx={{ mt: 2, textAlign: 'center' }}>
                           {t(`ORDER_REFLINK:smsVerification.${smsCodeError}`)}
                        </Alert>
                     )}
                  </DialogContent>
                  <DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                     <OutlinedButton variant="outlined" onClick={handleClose}>
                        {t('TRANSLATION:cancel')}
                     </OutlinedButton>
                     <ContainedButton
                        variant="contained"
                        type="button"
                        disabled={phoneNumberEror}
                        onClick={() => handleStepChange(1)}
                     >
                        {t('ORDER_REFLINK:smsVerification.smsSendButton')}
                     </ContainedButton>
                  </DialogActions>
               </Box>
            )
         case 1:
            return (
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     textAlign: 'center',
                     gap: 3,
                     m: 1,
                  }}
               >
                  <DialogTitle sx={{ textAlign: 'center' }} color="primary">
                     <Typography sx={{ fontSize: 20 }}>{t('ORDER_REFLINK:smsVerification.smsCodeTitle')}</Typography>
                  </DialogTitle>
                  <Typography variant="body1" color="grayText">
                     {t('ORDER_REFLINK:smsVerification.smsCodeText')}
                  </Typography>
                  <InputField name="smsCode" fullWidth={false} variant="standard" label="Kod SMS" />
                  <ContainedButton
                     onClick={handleSubmit}
                     disabled={isLoading}
                     variant="contained"
                     sx={{
                        fontSize: 13,
                     }}
                  >
                     {t('ORDER_REFLINK:smsVerification.verifyButton')}
                  </ContainedButton>
                  <Box sx={{ display: 'flex', gap: 3, flexDirection: 'column' }}>
                     <TypographyLink onClick={getSmsCode}>
                        {t('ORDER_REFLINK:smsVerification.sendAgainButton')}
                     </TypographyLink>

                     <TypographyLink onClick={() => setStep(0)}>
                        {t('ORDER_REFLINK:smsVerification.backToSendButton')}
                     </TypographyLink>
                  </Box>
               </Box>
            )
         default:
            return null
      }
   }
   return (
      <Dialog open={open} onClose={handleClose}>
         <FormikProvider value={formik}>
            <Form>{renderContent()}</Form>
         </FormikProvider>
      </Dialog>
   )
}
