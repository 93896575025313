import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CircularProgress from '@mui/material/CircularProgress'
import TableFooter from '@mui/material/TableFooter'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import MenuItem from '@mui/material/MenuItem'
import TableRow from '@mui/material/TableRow'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Box from '@mui/material/Box'

import { useMessagesQuery, useChangeMessageStatusMutation } from 'shared/store/useMessages'
import { Message } from 'shared/store/useMessages/types'

import { RootStyle, StyledTableCell, StyledTable, StyledTableRow, ReadTableCell, UnReadTableCell } from './styles'
import { pl, en, de } from './locales'
import Modal from './components/Modal'

export default function Messages() {
   const { i18n, t } = useTranslation(['TRANSLATION', 'MESSAGES'])
   const [activeMessage, setActiveMessage] = useState<Message>()
   const [itemsPerPage, setItemsPerPage] = useState(10)
   const { mutate } = useChangeMessageStatusMutation()
   const [activePage, setActivePage] = useState(0)
   const { data } = useMessagesQuery()
   const [modifedData, setModifiedData] = useState<Message[] | undefined>()
   i18n.addResourceBundle('pl-PL', 'MESSAGES', pl)
   i18n.addResourceBundle('en-EN', 'MESSAGES', en)
   i18n.addResourceBundle('de-DE', 'MESSAGES', de)

   function handleItemsPerPage(event: SelectChangeEvent<number>) {
      setActivePage(0)
      setItemsPerPage(event.target.value as number)
   }

   function handleModalOpen(messageUuid: string) {
      const tempActiveMessage = data?.find((item) => item.messageUuid === messageUuid)

      if (tempActiveMessage) {
         if (!tempActiveMessage.read) {
            const payload = {
               messageUuid,
            }
            mutate(payload, {
               onSettled: () => setActiveMessage(tempActiveMessage),
            })
         } else {
            setActiveMessage(tempActiveMessage)
         }
      }
      setActiveMessage(tempActiveMessage)
   }

   function renderMessage(messageText: Nullable<string>) {
      if (messageText) {
         return messageText.length > 40
            ? `${messageText.replaceAll('#', '').split('.')[0]}...`
            : messageText.replaceAll('#', '')
      }
      return ''
   }

   useEffect(() => {
      if (data) {
         setModifiedData(
            data
               .sort((a, b) => (a.read > b.read ? 1 : -1))
               .slice(itemsPerPage * activePage, itemsPerPage * activePage + itemsPerPage)
         )
      }
   }, [data, activePage, itemsPerPage])

   return (
      <RootStyle>
         <Box sx={{ width: '100%', backgroundColor: 'rgba(0,0,0,0)' }}>
            <Typography variant="h4" color="primary" sx={{ fontWeight: 'normal' }}>
               {t('MESSAGES:messages')}
            </Typography>
            {modifedData && data ? (
               <StyledTable sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                  <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                     <TableRow sx={{ borderRadius: '50%' }}>
                        <StyledTableCell>{t('MESSAGES:date')}</StyledTableCell>
                        <StyledTableCell>{t('MESSAGES:subject')}</StyledTableCell>
                        <StyledTableCell> {t('MESSAGES:contents')}</StyledTableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: 'rgba(255,255,255,0.7)' }}>
                     {modifedData.length > 0 ? (
                        modifedData.map((message) =>
                           message.read ? (
                              <StyledTableRow
                                 onClick={() => handleModalOpen(message.messageUuid)}
                                 key={message.messageUuid}
                              >
                                 <ReadTableCell>{message.createdDate}</ReadTableCell>
                                 <ReadTableCell>{message.title}</ReadTableCell>
                                 <ReadTableCell>{renderMessage(message.text)}</ReadTableCell>
                              </StyledTableRow>
                           ) : (
                              <StyledTableRow
                                 onClick={() => handleModalOpen(message.messageUuid)}
                                 key={message.messageUuid}
                              >
                                 <UnReadTableCell>{message.createdDate}</UnReadTableCell>
                                 <UnReadTableCell>{message.title}</UnReadTableCell>
                                 <UnReadTableCell>{renderMessage(message.text)}</UnReadTableCell>
                              </StyledTableRow>
                           )
                        )
                     ) : (
                        <TableRow>
                           <StyledTableCell sx={{ textAlign: 'center' }} colSpan={4}>
                              {t('MESSAGES:notFound')}
                           </StyledTableCell>
                        </TableRow>
                     )}
                  </TableBody>
                  <TableFooter sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                     <TableRow>
                        <TableCell sx={{ textAlign: 'right', paddingRight: 7 }} colSpan={4}>
                           {t('MESSAGES:itemsPerPage')}
                           <Select
                              sx={{ mx: 1, color: 'grayText', fontSize: 12 }}
                              variant="standard"
                              disabled={data.length === 0}
                              value={itemsPerPage}
                              onChange={(e) => handleItemsPerPage(e)}
                           >
                              <MenuItem value={5}> 5 </MenuItem>
                              <MenuItem value={10}> 10 </MenuItem>
                              <MenuItem value={15}> 15 </MenuItem>
                           </Select>
                           {t('MESSAGES:displayed', {
                              displayed: `${itemsPerPage * activePage + 1} - ${
                                 itemsPerPage * activePage + itemsPerPage > data.length
                                    ? data.length
                                    : itemsPerPage * activePage + itemsPerPage
                              }`,
                              all: data?.length,
                           })}
                           <IconButton
                              disabled={data.length === 0 || activePage === 0}
                              onClick={() => setActivePage((page) => page - 1)}
                           >
                              <ChevronLeftIcon />
                           </IconButton>
                           <IconButton
                              disabled={
                                 data
                                    ? data.length === 0 || activePage === Math.ceil(data.length / itemsPerPage) - 1
                                    : false
                              }
                              onClick={() => setActivePage((page) => page + 1)}
                           >
                              <ChevronRightIcon />
                           </IconButton>
                        </TableCell>
                     </TableRow>
                  </TableFooter>
               </StyledTable>
            ) : (
               <div style={{ width: '100%', textAlign: 'center' }}>
                  <CircularProgress color="primary" />
               </div>
            )}
         </Box>
         <Modal
            open={!!activeMessage}
            message={activeMessage?.text}
            title={activeMessage?.title}
            handleClose={() => setActiveMessage(undefined)}
         />
      </RootStyle>
   )
}
