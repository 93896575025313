import { AxiosError } from 'axios'

import { unauthHttpClient } from 'shared/lib'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { ReflinkDataType, UpdateAddressPayload } from '../types'

export function updateAddressService(reflinkUuid: string, verificationHeader: string, payload: UpdateAddressPayload) {
   let errMessage = ''
   return unauthHttpClient
      .patch<ReflinkDataType>(`/client-carts/reflinks/${reflinkUuid}`, payload, {
         headers: {
            'X-Reflink-Verification-Code': verificationHeader,
         },
      })
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('CLIENT_CART_REFLINK updateAddress service', errMessage, 'error')
         throw error
      })
}
