import { AxiosError } from 'axios'

import { unauthHttpClient } from 'shared/lib'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { SearchResponseType } from '../types'

export function getSearchDataService(reflinkUuid: string, verificationHeader: string, searchPhrase: string) {
   let errMessage = ''
   return unauthHttpClient
      .post<SearchResponseType>(
         `/client-carts/reflinks/${reflinkUuid}/products/search`,
         { searchPhrase },
         {
            headers: {
               'X-Reflink-Verification-Code': verificationHeader,
            },
         }
      )
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('CLIENT_CART_REFLINK getSearchData service', errMessage, 'error')
         throw error
      })
}
