import { AxiosError } from 'axios'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import consoleMessage from '../utils/helpers/consoleMessage'

export async function reFetchWholesalerData(productId: string, wholesalerId: string) {
   let errMessage = ''

   return httpClient
      .get<CooperatingWholesaler>(`products/${productId}/wholesales/${wholesalerId}/online-availability`)
      .then((res) => res.data)
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SHARED_SERVICES refetchWholesalerData service', errMessage, 'error')
         throw error
      })
}
