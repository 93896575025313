import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import { httpClient } from 'shared/lib'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { FetchNotificationsResult, NotificationActionsPayload } from './types'

export function fetchNotifications(): Promise<FetchNotificationsResult> {
   let errMessage = ''

   return httpClient
      .get('notifications/')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('NOTIFICATIONS fetchNotifications service', errMessage, 'error')
         throw error
      })
}

export function deleteNotification(payload: NotificationActionsPayload) {
   let errMessage = ''

   return httpClient
      .delete<Result>(`notifications/${payload.uuid}`)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('NOTIFICATIONS deleteNotification service', errMessage, 'error')
         throw error
      })
}

export function readNotification(payload: NotificationActionsPayload) {
   let errMessage = ''

   return httpClient
      .put(`notifications/${payload.uuid}/isRead/`)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('NOTIFICATIONS readNotification service', errMessage, 'error')
         throw error
      })
}
