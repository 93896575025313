import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { unauthHttpClient } from 'shared/lib'

import { CountriesType } from './types'

export function fetchCountries(): Promise<CountriesType> {
   let errMessage = ''

   return unauthHttpClient
      .get('countries')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('COUNTRIES fetchCountries service', errMessage, 'error')
         throw error
      })
}
