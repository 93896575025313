import { unauthHttpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { AxiosError } from 'axios'
import { EndReflinkPayload, ErrorType, ReflinkInfoType, Result } from '../types'

export function endReflinkService(reflinkUuid: string, payload: EndReflinkPayload) {
   let errMessage = ''

   return unauthHttpClient
      .post<Result>(`cooperation/reflink/${reflinkUuid}`, payload)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('COOPERATION_REFLINK endReflink service', errMessage, 'error')
         throw error
      })
}

export function getReflinkDetailsService(uuid: string): Promise<ReflinkInfoType> {
   let errMessage = ''

   return unauthHttpClient
      .get(`cooperation/reflink/${uuid}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('COOPERATION_REFLINK getReflinkDetails service', errMessage, 'error')
         throw error
      })
}

export function rejectReflinkService(uuid: string) {
   let errMessage = ''

   return unauthHttpClient
      .delete<Result>(`cooperation/reflink/${uuid}`)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('COOPERATION_REFLINK rejectReflink service', errMessage, 'error')
         throw error
      })
}
