import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { GetOrderResult } from './types'

export default function getOrderService(orderUuid: string) {
   let errMessage = ''

   return httpClient
      .get<GetOrderResult>(`orders/${orderUuid}`)
      .then((res) => ({
         status: res.status,
         data: res.data,
      }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('BASELINKER getOrder service', errMessage, 'error')
         throw error
      })
}
