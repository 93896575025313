import React, { useEffect, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import FormControl from '@mui/material/FormControl'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import Step from '@mui/material/Step'
import Box from '@mui/material/Box'

import validatePhoneNumber from 'shared/utils/helpers/validatePhoneNumber'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import getFingerprint from 'shared/utils/helpers/getFingerprint'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { unauthHttpClient } from 'shared/lib'

import { firstStepSchema, secondStepSchema, thirdStepSchema } from './schemas'
import { FirstStep, LastStep, SecondStep, ThirdStep } from './Steps'
import { ValidateSmsCodeResponse, RegisterResponse } from './types'

export default function RegisterForm() {
   const { t } = useTranslation(['REGISTER', 'GLOBALS'])
   const [activeStep, setActiveStep] = useState(0)
   const [phoneNumberError, setPhoneNumberError] = useState(false)
   const [searchParams] = useSearchParams()
   const [registerInProgress, setRegisterInProgress] = useState<boolean>(false)
   const [registerErrors, setRegisterErrors] = useState<string[]>([])
   const schemas = [firstStepSchema, secondStepSchema, thirdStepSchema, {}]
   const stepLabels = [
      t('REGISTER:stepper.step1'),
      t('REGISTER:stepper.step2'),
      t('REGISTER:stepper.step3'),
      t('REGISTER:stepper.step4'),
   ]
   const formik = useFormik<RegisterInitialValues>({
      initialValues: {
         email: '',
         firstName: '',
         acceptRegulations: false,
         lastName: '',
         houseNumber: '',
         companyName: '',
         telephoneNumber: '',
         city: '',
         county: '',
         voivodeship: '',
         distributorUuid: null,
         country: '',
         street: '',
         postCode: '',
         taxID: '',
         workshopEmployees: '0',
         shopEmployees: '0',
         afterSubmit: '',
         smsCode: '',
      },
      validationSchema: schemas[activeStep],
      onSubmit: handleSubmit,
   })

   async function validateSmsCode() {
      const { smsCode } = formik.values
      const { data, status } = await unauthHttpClient.post<ValidateSmsCodeResponse>('sms/verify', { smsCode })
      if (status === 200) {
         return data.valid
      }
      return false
   }

   const { values, setFieldValue, setFieldTouched } = formik

   function handlePhoneNumber(value: string, country: string) {
      setFieldValue('telephoneNumber', value)
      setFieldTouched('telephoneNumber')
      const result = validatePhoneNumber(value, country)
      if (result === false) {
         setPhoneNumberError(true)
      } else {
         setPhoneNumberError(false)
      }
   }
   async function register() {
      const fingerprint = await getFingerprint()
      setRegisterInProgress(true)
      setRegisterErrors([])
      const url = searchParams.get('r') ? `users/register?remarkText=${searchParams.get('r')}` : 'users/register'

      unauthHttpClient
         .post<RegisterResponse>(
            url,
            {
               acceptRegulations: values.acceptRegulations,
               address: {
                  city: values.city,
                  country: values.country,
                  houseNumber: values.houseNumber,
                  street: values.street,
                  zipCode: values.postCode,
                  voivodeship: values.voivodeship,
                  county: values.county,
               },
               companyName: values.companyName,
               email: values.email,
               name: values.firstName,
               phoneNumber: values.telephoneNumber,
               surname: values.lastName,
               distributorUuid: values.distributorUuid || null,
               taxID: values.taxID,
               typeService: {
                  shopPositions: values.shopEmployees,
                  workshopPositions: values.workshopEmployees,
               },
            },
            { headers: { 'X-Fingerprint': fingerprint } }
         )
         .then((res) => {
            if (res.status === 204) {
               setActiveStep(3)
            }
         })
         .catch((err) => {
            if (isAxiosError<ErrorType>(err)) {
               if (Array.isArray(err.response?.data.errors)) {
                  const errors = err.response ? err.response.data.errors.map((error) => error.code) : []
                  setRegisterErrors(errors)
               } else {
                  setRegisterErrors([t('apiErrors.500')])
               }
            }
         })
         .finally(() => setRegisterInProgress(false))
   }

   function handleSubmit() {
      setRegisterInProgress(true)
      formik.validateForm().then(async (errors) => {
         const isValidForm = Object.keys(errors).length === 0
         if (isValidForm) {
            const smsCodeIsValid = await validateSmsCode()
            if (smsCodeIsValid) {
               register()
            } else {
               setRegisterInProgress(false)
               formik.setFieldError('smsCode', 'REGISTER:errors.wrongSmsCode')
            }
         }
      })
   }

   function goStepBack() {
      const lastStep = activeStep - 1
      if (!formik.isValid) {
         formik.setErrors({})
      }
      setActiveStep(lastStep)
   }

   function goStepNext() {
      formik.validateForm().then((errors) => {
         const isValidForm = Object.keys(errors).length === 0
         if (isValidForm) {
            const nextStep = activeStep + 1
            setActiveStep(nextStep)
         }
      })
   }

   function renderStepContent(step: number) {
      switch (step) {
         case 0:
            return <FirstStep handlePhoneNumber={handlePhoneNumber} />
         case 1:
            return <SecondStep />
         case 2:
            return <ThirdStep />
         case 3:
            return <LastStep />
         default:
            return <div>{t('GLOBALS:notFound')}</div>
      }
   }

   function renderStepControls(step: number) {
      switch (step) {
         case 0:
            return (
               <>
                  <FormControl sx={{ mt: 1, ml: 3, mb: 2 }} variant="standard" />
                  <FormControl sx={{ m: 1, mr: 2 }} variant="standard">
                     <ContainedButton
                        onClick={goStepNext}
                        variant="contained"
                        sx={{ mt: 1, mb: 1 }}
                        disabled={!formik.isValid || phoneNumberError || (activeStep === 2 && !values.country)}
                     >
                        <ArrowForwardIosOutlinedIcon sx={{ fontSize: 24 }} />
                     </ContainedButton>
                  </FormControl>
               </>
            )
         case 2:
            return (
               <>
                  <FormControl sx={{ mt: 1, ml: 3, mb: 2 }} variant="standard">
                     <ContainedButton onClick={goStepBack} variant="contained" sx={{ mt: 1, mb: 1 }}>
                        <ArrowBackIosNewOutlinedIcon sx={{ fontSize: 24 }} />
                     </ContainedButton>
                  </FormControl>
                  <FormControl sx={{ m: 2, mr: 2 }} variant="standard">
                     <ContainedButton
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ mt: 1, mb: 1 }}
                        disabled={!formik.isValid || registerInProgress}
                     >
                        {t('REGISTER:mainSite.endRegistration')}
                     </ContainedButton>
                  </FormControl>
               </>
            )
         case 3:
            return null
         default:
            return (
               <>
                  <FormControl sx={{ mt: 1, ml: 3, mb: 2 }} variant="standard">
                     <ContainedButton onClick={goStepBack} variant="contained" sx={{ mt: 1, mb: 1 }}>
                        <ArrowBackIosNewOutlinedIcon sx={{ fontSize: 24 }} />
                     </ContainedButton>
                  </FormControl>
                  <FormControl sx={{ m: 2, mr: 2 }} variant="standard">
                     <ContainedButton
                        onClick={goStepNext}
                        variant="contained"
                        sx={{ mt: 1, mb: 1 }}
                        disabled={!formik.isValid}
                     >
                        <ArrowForwardIosOutlinedIcon sx={{ fontSize: 24 }} />
                     </ContainedButton>
                  </FormControl>
               </>
            )
      }
   }

   useEffect(() => {
      if (searchParams.get('id')) {
         formik.setFieldValue('distributorUuid', searchParams.get('id'))
      }
   }, [searchParams])

   return (
      <FormikProvider value={formik}>
         <Form>
            <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
               {stepLabels.map((label) => (
                  <Step
                     sx={{
                        '& .MuiStepLabel-root .MuiStepIcon-text': {
                           fill: 'white',
                        },
                     }}
                     key={label}
                  >
                     <StepLabel sx={{ fontWeight: 'bold' }}>{label}</StepLabel>
                  </Step>
               ))}
            </Stepper>

            {renderStepContent(activeStep)}

            <Stack direction="row" justifyContent="space-between">
               {renderStepControls(activeStep)}
            </Stack>
            <Box sx={{ my: registerErrors.length ? 3 : 9 }}>
               {registerErrors.length
                  ? registerErrors.map((error) => (
                       <Alert key={error} sx={{ mt: 1 }} severity="error">
                          {t(`TRANSLATION:${error}`)}
                       </Alert>
                    ))
                  : null}
            </Box>
         </Form>
      </FormikProvider>
   )
}
