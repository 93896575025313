import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { OrderDetailsType, Result, RatingPayload, ReportType } from './types'

export function getOrderDetailsService(orderUuids: string[]) {
   let errMessage = ''
   return httpClient
      .post<OrderDetailsType>('orders/search', { orderUuids })
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('ORDERS_HISTORY getOrderDetails service', errMessage, 'error')
         throw error
      })
}

export function addRatingService(payload: RatingPayload) {
   let errMessage = ''

   return httpClient
      .post<Result>('ratings/addRating', payload)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('ORDERS_HISTORY addRating service', errMessage, 'error')
         throw error
      })
}

export function getCsvOrderReportService(orderUuids: string[]) {
   let errMessage = ''

   return httpClient
      .post<ReportType>('reports/orders', { orderUuids })
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('ORDERS_HISTORY getCsvOrderReport service', errMessage, 'error')
         throw error
      })
}
