import { useQuery, UseQueryResult } from 'react-query'

import { FetchUserProfileResult } from './types'
import { fetchProfileData } from './services'

async function getUserProfileData() {
   const request = await fetchProfileData().then((response) => ({
      uuid: response.uuid,
      locationUuid: response.locationUuid,
      image: response.image,
      loyaltyPoints: response.loyaltyPoints,
      userName: response.userName,
      locationName: response.locationName,
      priceType: response.priceType,
      currency: response.currency,
      formattedCurrency: response.formattedCurrency,
   }))

   return request
}

export function useProfileQuery(): UseQueryResult<FetchUserProfileResult> {
   return useQuery('userProfile', getUserProfileData, { staleTime: 10 * (60 * 1000), refetchOnWindowFocus: 'always' })
}
