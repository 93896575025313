import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { AxiosError } from 'axios'
import { deleteNotification, fetchNotifications, readNotification } from './services'
import { NotificationActionsPayload, StateType } from './types'

async function getNotifications() {
   const request = await fetchNotifications().then((response) =>
      response.notifications.map((notification) => ({
         ...notification,
      }))
   )

   return request
}

export function useNotificationsQuery(): UseQueryResult<StateType> {
   return useQuery('notifications', getNotifications, { refetchOnWindowFocus: 'always' })
}

export function useRemoveNotification() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, NotificationActionsPayload, unknown>(
      (payload) => deleteNotification(payload),
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('notifications')
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}

export function useReadNotification() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, NotificationActionsPayload, unknown>(
      (payload) => readNotification(payload),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('notifications')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}
