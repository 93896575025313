import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { FetchProductsPayload, FetchProductsResult, Result, SendDuplicatesPayload } from './types'

export function fetchProducts(payload: FetchProductsPayload): Promise<FetchProductsResult> {
   let errMessage = ''

   return httpClient
      .post('products/search', {
         ...payload,
      })
      .then((res) => res.data)
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('PRODUCTS_LIST fetchProducts service', errMessage, 'error')
         throw error
      })
}

export function sendDuplicates(payload: SendDuplicatesPayload) {
   let errMessage = ''

   return httpClient
      .post<Result>('duplicates/add', payload)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('PRODUCTS_LIST sendDuplicates service', errMessage, 'error')
         throw error
      })
}

export async function getSubstitutesService(productUuid: string): Promise<FetchProductsResult> {
   let errMessage = ''

   return httpClient
      .get(`/products/replacements/${productUuid}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('PRODUCTS_LIST getSubstitutesService service', errMessage, 'error')
         throw error
      })
}
