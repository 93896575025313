import React, { useEffect, useState } from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import Collapse from '@mui/material/Collapse'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'
import { useQueryClient } from 'react-query'
import Tooltip from '@mui/material/Tooltip'

import { MAX_PRODUCT_QUANTITY } from 'shared/consts'
import { AdditionalServiceCartType, EditServicePayload } from 'shared/store/useCartSummary/types'
import { useUpdateServiceMutation } from 'shared/store/useCartSummary'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'

import {
   QuantityHolder,
   StyledDeleteIcon,
   StyledQuantityButton,
   StyledTableCell,
   StyledTableRow,
   StyledCollapseTableCell,
   StyledInput,
} from '../../../styles'
import { ClientViewPricesType, ModalInfoType } from '../../../types'

interface Props {
   services: AdditionalServiceCartType[]
   cartUuid: string
   clientView: boolean
   setModalInfo: (modalInfo: ModalInfoType | undefined) => void
}

export default function ServicesMobile({ services, setModalInfo, cartUuid, clientView }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const [clientPrices, setClientPrices] = useState<ClientViewPricesType>([])
   const [collapse, setCollapse] = useState<string[]>([])

   const { mutate, isLoading } = useUpdateServiceMutation()
   const queryClient = useQueryClient()

   function changeClientPrice(uuid: string, clientPrice: number, taxRate: number, type: 'NET' | 'GROSS') {
      let tempClientPrice
      if (type === 'NET') {
         tempClientPrice = (clientPrice * (1 + taxRate / 100)).toFixed(2)
         updateProduct(uuid, undefined, clientPrice.toFixed(2), tempClientPrice)
      } else {
         tempClientPrice = (clientPrice / (1 + taxRate / 100)).toFixed(2)
         updateProduct(uuid, undefined, tempClientPrice, clientPrice.toFixed(2))
      }
   }

   function updateProduct(uuid: string, quantity?: number, netPrice?: string, grossPrice?: string) {
      const mutatePayload: EditServicePayload = {
         netPrice,
         grossPrice,
         quantity,
      }
      mutate(
         { payload: mutatePayload, cartUuid, serviceUuid: uuid },
         {
            onSuccess: () => {
               invalidateCarts(queryClient)
               setModalInfo(undefined)
            },
         }
      )
   }

   function changeStateClientPrice(newClientPrice: number, uuid: string, taxRate: number) {
      setClientPrices((prevState) => {
         const newState = prevState.map((product) => {
            if (product.uuid === uuid) {
               return {
                  ...product,
                  clientPriceNet: Number(newClientPrice.toFixed(2)),
                  clientPriceGross: Number((newClientPrice * (1 + taxRate / 100)).toFixed(2)),
               }
            }
            return product
         })

         return newState
      })
   }
   function handleCollapseProduct(uuid: string) {
      if (collapse.includes(uuid)) {
         setCollapse(collapse.filter((item) => item !== uuid))
      } else {
         const tempCollapseProducts = [...collapse]
         tempCollapseProducts.push(uuid)
         setCollapse([...tempCollapseProducts])
      }
   }

   useEffect(() => {
      const tempClientPrices: ClientViewPricesType = []
      services.forEach((service) => {
         tempClientPrices.push({
            uuid: service.uuid,
            clientPriceNet: Number(service.netPrice),
            clientPriceGross: Number(service.grossPrice),
         })
      })
      setClientPrices([...tempClientPrices])
   }, [services])

   return services.length ? (
      <>
         {services.map((service) => (
            <React.Fragment key={service.uuid}>
               <StyledTableRow onClick={() => handleCollapseProduct(service.uuid)}>
                  <StyledTableCell colSpan={3}>{service.name}</StyledTableCell>
                  <StyledTableCell>
                     <StyledDeleteIcon
                        onClick={(e) => {
                           e.stopPropagation()
                           setModalInfo({
                              modalType: 'DELETE_SERVICE',
                              serviceUuid: service.uuid,
                              uuid: cartUuid,
                              index: service.name,
                              deleteService: true,
                           })
                        }}
                     />
                  </StyledTableCell>
               </StyledTableRow>
               <TableRow>
                  <TableCell style={{ padding: '0' }} colSpan={6}>
                     <Collapse
                        style={{ overflowY: 'scroll', maxHeight: '300px' }}
                        in={collapse.includes(service.uuid)}
                        timeout="auto"
                     >
                        <Table>
                           <TableBody>
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {t('TRANSLATION:columnNames:quantity')}
                                 </StyledCollapseTableCell>
                                 <StyledCollapseTableCell colSpan={3}>
                                    <QuantityHolder>
                                       <StyledQuantityButton
                                          type="button"
                                          disabled={isLoading || service.quantity === 0}
                                          onClick={() => updateProduct(service.uuid, service.quantity - 1)}
                                       >
                                          -
                                       </StyledQuantityButton>
                                       <StyledQuantityButton
                                          disabled={isLoading}
                                          type="button"
                                          onClick={() =>
                                             setModalInfo({
                                                modalType: 'QUANTITY',
                                                serviceUuid: service.uuid,
                                                changeServiceQuantity: true,
                                                uuid: cartUuid,
                                                quantity: service.quantity,
                                             })
                                          }
                                          style={{ fontWeight: 'normal' }}
                                       >
                                          {service.quantity}
                                       </StyledQuantityButton>
                                       <StyledQuantityButton
                                          type="button"
                                          disabled={isLoading || service.quantity === MAX_PRODUCT_QUANTITY}
                                          onClick={() => updateProduct(service.uuid, service.quantity + 1)}
                                       >
                                          +
                                       </StyledQuantityButton>
                                    </QuantityHolder>
                                 </StyledCollapseTableCell>
                              </TableRow>
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {t('TRANSLATION:columnNames:netPrice')}
                                 </StyledCollapseTableCell>
                                 <StyledCollapseTableCell>
                                    {clientView ? (
                                       <>
                                          <Tooltip title={t('MAIN_CART:clickToEdit') || ''}>
                                             <StyledInput
                                                onBlur={(e) =>
                                                   changeClientPrice(
                                                      service.uuid,
                                                      Number(e.target.value),
                                                      service.taxRate,
                                                      'NET'
                                                   )
                                                }
                                                onFocus={(e) => e.target.select()}
                                                type="number"
                                                onChange={(e) =>
                                                   changeStateClientPrice(
                                                      Number(e.target.value),
                                                      service.uuid,
                                                      service.taxRate
                                                   )
                                                }
                                                value={
                                                   clientPrices.find((item) => item.uuid === service.uuid)
                                                      ?.clientPriceNet
                                                }
                                             />
                                          </Tooltip>
                                          {service.preferredCurrency}
                                       </>
                                    ) : (
                                       service.formattedNetPrice
                                    )}
                                 </StyledCollapseTableCell>
                              </TableRow>
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>VAT</StyledCollapseTableCell>
                                 <StyledCollapseTableCell colSpan={3}>{service.taxRate}%</StyledCollapseTableCell>
                              </TableRow>
                              <TableRow>
                                 <StyledCollapseTableCell colSpan={3}>
                                    {t('TRANSLATION:columnNames:grossPrice')}
                                 </StyledCollapseTableCell>
                                 <StyledCollapseTableCell colSpan={3}>
                                    <Tooltip title={t('MAIN_CART:clickToEdit') || ''}>
                                       <StyledInput
                                          onBlur={(e) =>
                                             changeClientPrice(
                                                service.uuid,
                                                Number(e.target.value),
                                                service.taxRate,
                                                'GROSS'
                                             )
                                          }
                                          type="number"
                                          onFocus={(e) => e.target.select()}
                                          onChange={(e) =>
                                             changeStateClientPrice(
                                                Number(e.target.value) / (1 + service.taxRate / 100),
                                                service.uuid,
                                                service.taxRate
                                             )
                                          }
                                          value={
                                             clientPrices.find((item) => item.uuid === service.uuid)?.clientPriceGross
                                          }
                                       />
                                    </Tooltip>
                                    {service.preferredCurrency}
                                 </StyledCollapseTableCell>
                              </TableRow>
                           </TableBody>
                        </Table>
                     </Collapse>
                  </TableCell>
               </TableRow>
            </React.Fragment>
         ))}
      </>
   ) : (
      <TableRow>
         <TableCell colSpan={10}>
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  opacity: 0.6,
                  fontWeight: 'bold',
                  color: 'grayText',
               }}
            >
               {t('MAIN_CART:noProducts')}
            </Box>
         </TableCell>
      </TableRow>
   )
}
